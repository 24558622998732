<template>
	<div class="button" :class="classes" @click="clicked">
		<slot />
	</div>
</template>

<script>
export default {
	name: "Button",

	props: {
		inverse: {
			type: Boolean,
			required: false,
			default: false
		},

		disabled: {
			type: Boolean,
			required: false,
			default: false
		}
	},

	emits: ["click"],

	computed: {
		classes () {
			let classes = [];

			if (this.inverse)
				classes.push("inverse");

			if (this.disabled)
				classes.push("disabled");

			return classes.join(" ");
		}
	},

	methods: {
		clicked () {
			if (!this.disabled)
				this.$emit("click");
		}
	}
};
</script>

<style scoped>

.button {
	background-color: #d9d9d9;
	cursor: pointer;
	color: black;
	border-radius: 8px;
	font-size: 12pt;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	padding: 10px;
	display: flex;
	align-items: center;
	justify-content: center;;
}

.button:hover:not(.disabled) {
	background-color: #e6e6e6;
}

.inverse {
	border: 1px solid #888;
	background-color: white;
	color: #333;
	border-radius: 12px;
}

.inverse:hover:not(.disabled) {
	background-color: #d9d9d9;
}

.disabled {
	cursor: default;
	color: #888;
	border-color: #bbb !important;
}
</style>
