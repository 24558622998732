<template>
	<ul>
		<li v-for="(item, index) in modelValue" :key="item">
			<Button
				inverse
				:disabled="disabled"
				class="remove"
				@click="$emit('click', item, index)"
			>
				{{ item }}
			</button>
		</li>
	</ul>
</template>

<script>
import Button from "./Button";

export default {
	name: "ButtonList",

	components: {
		Button
	},

	props: {
		modelValue: {
			type: Array,
			required: true
		},

		disabled: {
			type: Boolean,
			required: false,
			default: false
		}
	},

	emits: ["click"]
};
</script>

<style scoped>
ul {
	list-style-type: none;
	margin: 0px;
	padding: 0px;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}

li {
	margin: 5px;
}
</style>
