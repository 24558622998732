<template>
	<div>
		<label :for="safeName">
			{{ name }}<span v-if="required">*</span>
		</label>

		<div class="input">
			<input
				:id="safeName"
				:type="type"
				:placeholder="placeholder"
				:value="modelValue"
				:disabled="disabled"
				@input="$emit('update:modelValue', $event.target.value)"
				@keyup.enter="$emit('submit')"
			>

			<slot name="icon" />
		</div>

		<p class="note">
			<slot name="note" />
		</p>
	</div>
</template>

<script>
export default {
	name: "InputField",

	props: {
		name: {
			type: String,
			required: true
		},

		modelValue: {
			type: String,
			required: false,
			default: ""
		},

		placeholder: {
			type: String,
			required: false,
			default: ""
		},

		type: {
			type: String,
			required: false,
			default: "text"
		},

		required: {
			type: Boolean,
			required: false,
			default: false
		},

		disabled: {
			type: Boolean,
			required: false,
			default: false
		}
	},

	emits: ["update:modelValue", "submit"],

	computed: {
		safeName () {
			return this.name.toLowerCase().replace(/[^\w]/gi, "");
		}
	}
};
</script>

<style scoped>
input {
	display: block;
	width: 100%;
	box-sizing: border-box;
	padding: 10px 0;
	font-size: 12pt;
	padding-left: 10px;
}

label {
	color: #999;
}

.input {
	display: flex;
	flex-direction: row;
}

.note {
	color: #555;
	font-size: small;
}
</style>
