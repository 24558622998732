<template>
	<div :class="type">
		{{ message }}
	</div>
</template>

<script>
export default {
	name: "Message",

	props: {
		message: {
			type: String,
			required: false,
			default: "Something has gone wrong!"
		},

		type: {
			type: String,
			required: false,
			default: "info"
		}
	}
};
</script>

<style scoped>
div {
	background-color: #ff8080;
	border: 2px solid black;
	border-radius: 8px;
	padding: 15px;
	text-align: center;
	vertical-align: bottom;
	margin: 10px 0;
	color: white;
	font-weight: bold;
	box-sizing: border-box;
}

div.info {
	background-color: #3bf;
	border-color: #0af;
}

div.error {
	background-color: #ff8080;
	border-color: #f66;
}
</style>
