<template>
	<InputField
		v-model="newValue"
		:name="name"
		:placeholder="placeholder"
		:type="type"
		:required="required"
		:disabled="disabled"
		class="input-field"
		@submit="submit"
	>
		<template #icon>
			<Button
				:disabled="disabled"
				class="add"
				@click="submit"
			>
				Add
			</button>
		</template>
	</InputField>
</template>

<script>
import InputField	from "./InputField";
import Button		from "./Button";

export default {
	name: "InputButton",

	components: {
		InputField,
		Button
	},

	props: {
		name: {
			type: String,
			required: true
		},

		modelValue: {
			type: String,
			required: false,
			default: ""
		},

		placeholder: {
			type: String,
			required: false,
			default: ""
		},

		type: {
			type: String,
			required: false,
			default: "text"
		},

		required: {
			type: Boolean,
			required: false,
			default: false
		},

		disabled: {
			type: Boolean,
			required: false,
			default: false
		}
	},

	emits: ["submit", "update:modelValue"],

	data () {
		return {
			newValue: ""
		};
	},

	watch: {
		newValue (value) {
			if (this.modelValue !== value)
				this.$emit("update:modelValue", value);
		},

		modelValue (value) {
			this.newValue = value;
		}
	},

	beforeMount () {
		this.newValue = this.modelValue;
	},

	methods: {
		submit () {
			this.$emit("submit", this.newValue);
			this.newValue = "";
		}
	}
};
</script>

<style scoped>
.add {
	padding-right: 20px;
	padding-left: 20px;
	border-radius: 0px 2px 2px 0px;
	border: 1px solid #888;
	border-left: 0px;
}
</style>

<style>
.input-field > div > input:enabled {
	border: 1px solid #888;
	border-radius: 2px 0px 0px 2px;
	border-right: 0px;
}
</style>
